<template>
    <div class="split content split-horizontal secondaryBG second-wishlist" id="charScreeen">
        <!-- <span style="display: none">{{ create_liveCandle }}</span> -->
        <div class="stx-dialog-container" @click.stop="">
            <div id="contextMenu" style="display: none" class="stx-dialog">
                <div class="d-flex align-items-center justify-content-between p-2">
                    <h4 class="title m-0"></h4>
                    <div class="d-flex align-items-center">
                        <div class="stx-delete stx-trash mr-2">
                            <vue-feather type="trash-2"></vue-feather>
                        </div>
                        <div @click="dismissDialog()" class="stx-btn stx-ico position-relative">
                            <span class="stx-ico-close m-0"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="studyDialog" style="display: none" class="stx-dialog" ref="studyDialog">
                <div class="indicatorHeader border-bottom p-2">
                    <h4 class="title"></h4>
                    <div @click="dismissDialog()" class="stx-btn stx-ico">
                        <span class="stx-ico-close"></span>
                    </div>
                </div>
                <div class="popup-inner-scroll scrollbar p-2">
                    <div id="inputs">
                        <div class="inputTemplate" style="display: none">
                            <div class="stx-heading"></div>
                            <div class="stx-data"></div>
                        </div>
                    </div>
                    <div id="outputs">
                        <hr />
                        <div class="outputTemplate" style="display: none">
                            <div class="stx-heading"></div>
                            <div class="stx-color"><span></span></div>
                        </div>
                    </div>
                </div>
                <div class="footerIndicator p-2 border-top">
                    <div id="parameters"></div>
                    <div @click="createStudy()" class="stx-btn">Create</div>
                </div>
            </div>
        </div>
        <div class="insertTP position-relative p-0">
            <div class="favourite_sec modernChartIQ d-flex align-items-center justify-content-between">
                <div class="leftHeader d-flex align-items-center">
                    <router-link to="/" class="chartSmallLogo me-3"><img src="assets/images/logo-small.svg"
                            alt="Portfolios" title="Logo" /></router-link>
                    <div class="d-inline-flex align-items-center chartSellBuy">
                        <div class="ltChartName px-3">
                            <div class="d-flex align-items-center">
                                <div class="star" v-if="watchId">
                                    <a href="javascript:void(0)" @click="addRemFev(loadChart)"
                                        :class="[{ favSelected: checkFev }]">
                                        <vue-feather type="star"></vue-feather>
                                    </a>
                                </div>
                                <div class="loadChart">
                                    <h3 class="f-16 fw-500 mb-0">{{ loadChart }}</h3>
                                    <div class="d-flex align-items-center">
                                        <img class="mr-2" alt="Icon" :src="
                                          'assets/images/crypto/' +
                                          loadChart.split('-')[0].toLowerCase() +
                                          '.webp'
                                        " @error="
                        handleImgErr(loadChart.split('-')[0].toLowerCase())
                      " v-show="
                        !errorImage.includes(
                          loadChart.split('-')[0].toLowerCase()
                        )
                      " />
                                        <p class="mb-0" v-if="instruments[loadChart]">
                                            {{ instruments[loadChart].other.Description }}
                                        </p>
                                        <p class="mb-0" v-else>-</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center ml-3">
                            <div class="priceArea d-flex align-items-center" v-if="instruments[loadChart]">
                                <span class="arrow">
                                    <vue-feather :type="
                                      instruments[loadChart].buy_increase_staus
                                        ? 'arrow-up'
                                        : 'arrow-down'
                                    " :class="[
                      { green: instruments[loadChart].buy_increase_staus },
                      { red: !instruments[loadChart].buy_increase_staus },
                    ]"></vue-feather>
                                </span>
                                <h3 :class="[
                                  { green: instruments[loadChart].buy_increase_staus },
                                  { red: !instruments[loadChart].buy_increase_staus },
                                ]">
                                    {{ formatfloat(instruments[loadChart].B) }}
                                </h3>
                            </div>
                            <div class="ml-4 value">
                                <p class="mb-1">24h Change</p>
                                <h6 class="mb-0" :class="[
                                  {
                                    green:
                                      instruments[loadChart].B -
                                        instruments[loadChart].other.closepx >
                                      0,
                                    'red font-weight-bold':
                                      instruments[loadChart].B -
                                        instruments[loadChart].other.closepx <
                                      0,
                                  },
                                ]" v-if="instruments[loadChart]">
                                    ({{
                                    parseFloat(
                                    (
                                    ((instruments[loadChart].B -
                                    instruments[loadChart].other.closepx) /
                                    instruments[loadChart].other.closepx) *
                                    100
                                    ).toFixed(3)
                                    )
                                    }}%)
                                </h6>
                                <h6 class="mb-0" v-else>-</h6>
                            </div>
                            <div class="ml-4 value">
                                <p class="mb-1">24h High</p>
                                <h6 class="mb-0">{{ ChartValues.High || "-" }}</h6>
                            </div>
                            <div class="ml-4 value">
                                <p class="mb-1">24h Low</p>
                                <h6 class="mb-0">{{ ChartValues.Low || "-" }}</h6>
                            </div>
                            <div class="ml-4 value">
                                <p class="mb-1">24h Volume (BTC)</p>
                                <h6 class="mb-0">-</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttonMobile justify-content-end">
                    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        class="btn buysell greenBG uppercase ml-2" @click="() => initIdea()">
                        Post Idea
                    </button>
                </div>
            </div>
            <div class="chartInstrument favourite_sec d-flex align-items-center justify-content-between border-top">
                <div class="d-flex">
                    <div class="barMenu">
                        <a @click="() => (showIsinsList = !showIsinsList)">
                            <i class="fa fa-bars" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div class="inLine-chart d-flex">
                        <div class="mobileSelect" style="display: none">
                            <button type="button" data-toggle="dropdown"
                                :data-title="intervalTitle[defaultChartInterval]"
                                class="p-0 appButton-r d-flex align-items-center justify-content-center">
                                {{ defaultChartInterval }}
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" :class="defaultChartInterval == val.data ? 'active' : ''"
                                    href="#" v-for="val in chartInterval" @click="changeInterval(val)">{{ val.unit }}{{
                                    val.name }}</a>
                            </div>
                        </div>
                        <a class="dropdown-item dasktopShow" :class="defaultChartInterval == val.data ? 'active' : ''"
                            href="#" v-for="val in chartInterval" @click="changeInterval(val)">{{ val.data }}</a>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                    <div class="inLine-chart displayGrapph dropdown">
                        <button type="button" data-bs-toggle="dropdown" :data-title="ChartName(chartType)"
                            class="p-0 appButton-r d-flex align-items-center justify-content-center dropdown-toggle">
                            <i class="add" :class="ChartClass(chartType)"></i>
                        </button>
                        <div class="dropdown-menu selectLine-chart">
                            <a href="#" class="dropdown-item d-flex align-items-center"
                                :class="chartType == chart.type ? 'active' : ''" v-for="chart in chartTypelist"
                                @click.prevent="changeChartType(chart.type)">
                                <i class="add mr-1" :class="chart.class"></i>{{ chart.value }}
                            </a>
                        </div>
                    </div>
                    <div class="inLine-chart displayGrapph dropdown">
                        <button type="button" data-bs-toggle="dropdown" data-title="Indicators"
                            class="appButton-r d-flex align-items-center justify-content-center">
                            <i class="add indicators"></i>
                        </button>
                        <div class="dropdown-menu">
                            <div class="search_share_sec d-flex align-items-center">
                                <vue-feather type="search"></vue-feather>
                                <input type="text" placeholder="Search for indicators" id="search_share"
                                    class="search_txtin" v-model="searchStudy" autocomplete="off" />
                            </div>
                            <div class="scrollbar studieList">
                                <a class="dropdown-item" href="#" v-for="(study, name) in studyListfunc"
                                    @click.prevent="addStudie(name)">{{ study }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="graph_option_sec d-flex align-items-center">
                        <!-- <button type="button" data-title="Load Layouts" class="appButton-r d-flex align-items-center justify-content-between inLine-chart" @click="ShowUploads=true"> <vue-feather size="16px" type="upload-cloud"></vue-feather> </button> <div class="inLine-chart"> <button type="button" data-toggle="dropdown" class="svgCode w-auto white appButton-r height-auto min-80 d-flex align-items-center" :data-title=" layoutSelected ? 'Layout Selected: ' + getCurrentLayout() : 'Save Layout' " > <span class="mr-1"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 18" width="22" height="18"> <path d="M12 0C8.4 0 5.4 2.8 5.1 6.3 2 6.1.1 9 0 11.1 0 13.9 2.2 16 4.7 16H9v-1H4.7C2.8 15 1 13.3 1 11.2 1 9 3 6.7 6 7.4 6 3 9 1 12 1s3.7 1.2 5.1 2.9c1.5.1 3.3.6 4.2 1.7 1 1.5 1.2 2.6 1.2 4.2 1.4.4 2.5 1.5 2.5 2.7-.2 1.6-1.3 2.5-2.6 2.5H17v1h5.3c2.8 0 3.7-1.9 3.7-3.5s-1-3-2.5-3.4c0-1.6-.5-2.9-1.4-4.1-.9-1.2-2.7-2-4.5-2.1C16.3 1.2 14.3 0 12 0zm1 6l-6 6h3v5h6v-5h3l-6-6z" ></path> </svg> </span>{{layoutSelected ? layoutName() : "Save"}}</button> <div class="dropdown-menu"> <a class="dropdown-item" href="javascript:void(0)" @click="saveLayoutClick()">Save Chart Layout</a> <a class="dropdown-item" href="javascript:void(0)" @click="saveAsClick()">Save As...</a> <a class="dropdown-item" href="javascript:void(0)" @click="renameClick()">Rename...</a> </div></div><div class="inLine-chart displayGrapph alertPad maskIcon"> <button data-title="Create Alert" @click.prevent="alertTab=true" type="button" class="appButton-r d-flex align-items-center justify-content-center p-0"><span class="maskElement alertIcon"></span></button> </div>-->
                        <div class="inLine-chart displayGrapph minimize">
                            <button data-title="Maximize / Collapse" type="button"
                                class="appButton-r d-flex align-items-center justify-content-between"
                                @click="openFullscreen()">
                                <vue-feather size="16px" type="minimize"></vue-feather>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="showWatchlist d-flex align-items-start" :class="{ open: showIsinsList, close: !showIsinsList }">
            <div class="leftWatchlist">
                <div class="m-1 px-1 seracWatch">
                    <input class="form-control" type="text" v-model="search" placeholder="Search symbol" />
                </div>
                <div class="whatchListCalc">
                    <div class="table-responsive">
                        <table class="table watchlistTable w-100 fixed-layout">
                            <thead>
                                <tr>
                                    <th>Symbols</th>
                                    <th class="text-center">Price</th>
                                    <th class="text-end">Change</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr @click="() => changeSymbol(isin)" v-for="(isin, key) of isins"
                                    :class="{ active: isin.other.isinid === loadChart }">
                                    <td>
                                        <span class="pointer d-flex align-items-center mb-1 line-1">{{ isin.other.isinid
                                        }}</span>
                                        <!-- <span class="pointer d-block line-1">{{isin.other.Description}}</span> -->
                                    </td>
                                    <td class="text-center">{{ COMMON.formatPrice(isin.B) }}</td>
                                    <td class="text-end" :class="COMMON.formatPrice(isin.CB) > 0 ? 'green' : 'red'">
                                        {{ COMMON.formatPrice(isin.CB) }}%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="fullchart" :class="studdyActive ? 'show-desc' : ''">
                <div class="d-flex border calHight position-relative">
                    <div class="leftNva-inchart">
                        <ul class="scrollbar" id="studytools" style="scrollbar-width: none !important">
                            <li v-for="(item, key) in tools" v-show="key" class="position-relative drawingTools" :class="[
                              {
                                active: toolSelected != '' && toolSelected == item,
                                [item]: true,
                              },
                            ]">
                                <a href="#" :title="item" @click.prevent="enableDrawingTool(item)">
                                    
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="chartMap chartContainer"></div>
                </div>
            </div>
            <!-- <div class="rightSide-section d-flex echoNews scrollbar">
                <div class="leftShow-bar border p-2 economic_calendar_chart" v-if="taskTab == 'EconomicCalendar'">
                    <div class="headerTitle">
                        <h6>Economic Calendar</h6>
                    </div>
                    <div class="scrollBar table-responsive economic border-0">
                        <div class="card border-0">
                            <div class="card-header border-0 p-0" id="headingOne">
                                <button class="m-0 d-flex align-items-center justify-content-between themeBG w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Monday, 25 January 2021 
                                    <vue-feather size="15px" type="chevron-down"></vue-feather>
                                </button>
                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body p-0">
                                        <div class="showCal border-top">
                                            <div class="d-flex"> 
                                                <span class="crncyIcon me-2"><img width="20" src="assets/images/country_flag/united states.webp" alt="Icon"></span> 
                                                <span class="rightText">
                                                <h5 class="m-0">Claimant Count Change</h5>
                                                <p class="small m-0 d-block">05:30 am</p>
                                                </span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-center mt-1">
                                                <div class="inblt">
                                                <span class="f-11">Actual</span>
                                                <h5 class="f-11">0.30</h5>
                                                </div>
                                                <div class="inblt">
                                                <span class="f-11">Previous</span>
                                                <h5 class="f-11">0.20</h5>
                                                </div>
                                                <div class="inblt">
                                                <span class="f-11">Forecast</span>
                                                <h5 class="f-11">91.8</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="showCal border-top">
                                            <div class="d-flex"> 
                                                <span class="crncyIcon me-2"><img width="20" src="assets/images/country_flag/united states.webp" alt="Icon"></span> 
                                                <span class="rightText">
                                                <h5 class="m-0">Claimant Count Change</h5>
                                                <p class="small m-0 d-block">05:30 am</p>
                                                </span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-center mt-1">
                                                <div class="inblt">
                                                <span class="f-11">Actual</span>
                                                <h5 class="f-11">0.30</h5>
                                                </div>
                                                <div class="inblt">
                                                <span class="f-11">Previous</span>
                                                <h5 class="f-11">0.20</h5>
                                                </div>
                                                <div class="inblt">
                                                <span class="f-11">Forecast</span>
                                                <h5 class="f-11">91.8</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="showCal border-top">
                                            <div class="d-flex"> 
                                                <span class="crncyIcon me-2"><img width="20" src="assets/images/country_flag/united states.webp" alt="Icon"></span> 
                                                <span class="rightText">
                                                <h5 class="m-0">Claimant Count Change</h5>
                                                <p class="small m-0 d-block">05:30 am</p>
                                                </span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-center mt-1">
                                                <div class="inblt">
                                                <span class="f-11">Actual</span>
                                                <h5 class="f-11">0.30</h5>
                                                </div>
                                                <div class="inblt">
                                                <span class="f-11">Previous</span>
                                                <h5 class="f-11">0.20</h5>
                                                </div>
                                                <div class="inblt">
                                                <span class="f-11">Forecast</span>
                                                <h5 class="f-11">91.8</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                <div class="leftShow-bar border p-2 economic_calendar_chart" v-if="taskTab == 'news'">
                    <div class="headerTitle">
                        <h6 class="mb-1">News</h6>
                    </div>
                    <div class="leftData scrollBar">
                          <div class="elementHistoy border-bottom">
                            <span class="buttonType">Housing Starts</span>
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <p>Housing starts in the US surged 5.8 percent month-over-month to an annualized rate of 1669 thousand in December of 2020, beating market forecasts of 1</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-11 uppercase m-0">UNITEDSTAHOUSTA,</p>
                                <span class="f-11 date d-flex align-items-center"><feather class="mr-1" size="12px" type="calendar"></feather>6 hours ago</span>
                            </div>
                        </div>
                        <div class="elementHistoy border-bottom">
                            <span class="buttonType">Housing Starts</span>
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <p>Housing starts in the US surged 5.8 percent month-over-month to an annualized rate of 1669 thousand in December of 2020, beating market forecasts of 1</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-11 uppercase m-0">UNITEDSTAHOUSTA,</p>
                                <span class="f-11 date d-flex align-items-center"><feather class="mr-1" size="12px" type="calendar"></feather>6 hours ago</span>
                            </div>
                        </div>
                        <div class="elementHistoy border-bottom">
                            <span class="buttonType">Housing Starts</span>
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <p>Housing starts in the US surged 5.8 percent month-over-month to an annualized rate of 1669 thousand in December of 2020, beating market forecasts of 1</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-11 uppercase m-0">UNITEDSTAHOUSTA,</p>
                                <span class="f-11 date d-flex align-items-center"><feather class="mr-1" size="12px" type="calendar"></feather>6 hours ago</span>
                            </div>
                        </div>
                        <div class="elementHistoy border-bottom">
                            <span class="buttonType">Housing Starts</span>
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <p>Housing starts in the US surged 5.8 percent month-over-month to an annualized rate of 1669 thousand in December of 2020, beating market forecasts of 1</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-11 uppercase m-0">UNITEDSTAHOUSTA,</p>
                                <span class="f-11 date d-flex align-items-center"><feather class="mr-1" size="12px" type="calendar"></feather>6 hours ago</span>
                            </div>
                        </div>
                        <div class="elementHistoy border-bottom">
                            <span class="buttonType">Housing Starts</span>
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <p>Housing starts in the US surged 5.8 percent month-over-month to an annualized rate of 1669 thousand in December of 2020, beating market forecasts of 1</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-11 uppercase m-0">UNITEDSTAHOUSTA,</p>
                                <span class="f-11 date d-flex align-items-center"><feather class="mr-1" size="12px" type="calendar"></feather>6 hours ago</span>
                            </div>
                        </div>
                        <div class="elementHistoy border-bottom">
                            <span class="buttonType">Housing Starts</span>
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <p>Housing starts in the US surged 5.8 percent month-over-month to an annualized rate of 1669 thousand in December of 2020, beating market forecasts of 1</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-11 uppercase m-0">UNITEDSTAHOUSTA,</p>
                                <span class="f-11 date d-flex align-items-center"><feather class="mr-1" size="12px" type="calendar"></feather>6 hours ago</span>
                            </div>
                        </div>
                        <div class="elementHistoy border-bottom">
                            <span class="buttonType">Housing Starts</span>
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <p>Housing starts in the US surged 5.8 percent month-over-month to an annualized rate of 1669 thousand in December of 2020, beating market forecasts of 1</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-11 uppercase m-0">UNITEDSTAHOUSTA,</p>
                                <span class="f-11 date d-flex align-items-center"><feather class="mr-1" size="12px" type="calendar"></feather>6 hours ago</span>
                            </div>
                        </div>
                        <div class="elementHistoy border-bottom">
                            <span class="buttonType">Housing Starts</span>
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <p>Housing starts in the US surged 5.8 percent month-over-month to an annualized rate of 1669 thousand in December of 2020, beating market forecasts of 1</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-11 uppercase m-0">UNITEDSTAHOUSTA,</p>
                                <span class="f-11 date d-flex align-items-center"><feather class="mr-1" size="12px" type="calendar"></feather>6 hours ago</span>
                            </div>
                        </div>
                        <div class="elementHistoy border-bottom">
                            <span class="buttonType">Housing Starts</span>
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <p>Housing starts in the US surged 5.8 percent month-over-month to an annualized rate of 1669 thousand in December of 2020, beating market forecasts of 1</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-11 uppercase m-0">UNITEDSTAHOUSTA,</p>
                                <span class="f-11 date d-flex align-items-center"><feather class="mr-1" size="12px" type="calendar"></feather>6 hours ago</span>
                            </div>
                        </div>
                        <div class="elementHistoy border-bottom">
                            <span class="buttonType">Housing Starts</span>
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <p>Housing starts in the US surged 5.8 percent month-over-month to an annualized rate of 1669 thousand in December of 2020, beating market forecasts of 1</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-11 uppercase m-0">UNITEDSTAHOUSTA,</p>
                                <span class="f-11 date d-flex align-items-center"><feather class="mr-1" size="12px" type="calendar"></feather>6 hours ago</span>
                            </div>
                        </div>
                    </div>  
                </div>
                <div class="leftShow-bar border p-2 economic_calendar_chart" v-if="taskTab == 'idea'">
                    <div class="headerTitle">
                        <h6>Idea</h6>
                    </div>
                    <div class="leftData scrollBar">
                        <div class="elementHistoy border-bottom">
                            <h5>US Housing Starts Highest in 14 Years</h5>
                            <img class="w-100" src="assets/images/chart/chartpattern.svg" alt="Chart" title="Chart"/>
                            <div class="d-flex align-items-center justify-content-between ideas-currency-row my-2">
                                <div class="forexnood d-flex align-items-center">
                                    <span class="mx-2 medium">forexnoob</span> 
                                </div>     
                                <div class="currency-wrap">
                                    <span class="monthly"><a href="#">monthly</a></span>
                                    <span class="short"><a href="#" class="redBG"><i data-feather="arrow-down-right" ></i> Short</a></span>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                <div class="rightTabs border d-flex bg-white">
                    <ul class="rightTabs-list w-100">
                        <li>
                            <a title="Economic Calendar" @click.prevent="taskTab = 'EconomicCalendar'" :class="(taskTab == 'EconomicCalendar')?'active':''" class="navLink" href="javascript:void(0)"><i class="maskIocn icon-1"></i></a>
                        </li>
                        <li>
                            <a title="News" @click.prevent="taskTab = 'news'" :class="(taskTab == 'news')?'active':''" class="navLink" href="javascript:void(0)"><i class="maskIocn icon-2"></i></a>
                        </li>
                        <li>
                            <a title="Idea" @click.prevent="taskTab = 'idea'" :class="(taskTab == 'idea')?'active':''" class="navLink" href="javascript:void(0)"><i class="maskIocn icon-3"></i></a>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>
        <div class="customFixed-vue darkFadeBG selectWatch-pop" v-if="Object.keys(renamelayout).length > 0">
            <div class="custom-modal showTabs secondary-color headerTab newsResize openWatchlist">
                <span class="closePOP" @click.prevent="renamelayout = {}">
                    <vue-feather size="13px" type="x"></vue-feather>
                </span>
                <div class="pop-head-title">
                    <p class="mb-3">Rename Chart Layout</p>
                </div>
                <div class="topSettingHead position-relative border-bottom p-3 updateWrap">
                    <div class="form-fields mb-0">
                        <label>Enter a new chart layout name:</label>
                        <input type="text" class="form-controls" placeholder=" " name="Layout Name"
                            v-model="renamelayout.layout_name" rules="'required|max:20'" maxlength="20" />
                        <span class="text-danger" v-if="submitted && errors.has('Layout Name')">{{ errors.first("Layout Name") }}</span>
                        <span class="d-flex align-items-center justify-content-center mt-4">
                            <a href="#" class="noAdd mr-3" @click.prevent="renamelayout = {}">Cancel</a>
                            <button class="btn appButton linkBG justify-content-center" @click="saveRenamelayout()">
                                Save
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="customFixed-vue darkFadeBG selectWatch-pop" v-if="showCreateLayout">
            <div class="custom-modal showTabs secondary-color headerTab newsResize openWatchlist">
                <span class="closePOP" @click.prevent="closeCreate()">
                    <vue-feather size="13px" type="x"></vue-feather>
                </span>
                <div class="pop-head-title">
                    <p class="mb-1" v-if="saveAsLayout">Copy Chart Layout</p>
                    <p class="mb-1" v-else>Save New Chart Layout</p>
                </div>
                <div class="topSettingHead position-relative border-bottom p-3 updateWrap">
                    <div class="form-fields mb-0">
                        <label v-if="saveAsLayout" class="mb-1">Enter a new chart layout name:</label>
                        <label v-else class="mb-1">Chart layout name:</label>
                        <input type="text" class="form-controls" placeholder=" " name="Layout Name"
                            v-model="new_layout_name" rules="'required|max:20'" maxlength="20" />
                        <span class="text-danger" v-if="submitted && errors.has('Layout Name')">{{ errors.first("Layout Name") }}</span>
                        <span class="d-flex align-items-center justify-content-center mt-4">
                            <a href="#" class="noAdd d-inline-flex justify-content-center mr-3"
                                @click.prevent="closeCreate()">Cancel</a>
                            <button class="btn appButton linkBG justify-content-center" @click="createNewlayout()">
                                Save
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="customFixed-vue alertPOP darkFadeBG heightAuto priceAlert" v-if="ShowUploads">
            <div class="custom-modal showTabs secondary-color openHeader">
                <div class="mainContent">
                    <div class="topSettingHead position-relative border-bottom p-3">
                        <p class="f-14 mb-0">Load Chart Layout</p>
                        <span class="closePOP" @click.prevent="ShowUploads = false">
                            <vue-feather size="13px" type="x"></vue-feather>
                        </span>
                    </div>
                    <div class="mainTab-one mt-2 px-2">
                        <div class="chartLoadLayoutName">
                            <ul class="d-flex align-items-center justify-content-between"
                                v-for="item in getLayoutList()">
                                <li>
                                    <span>Chart Layout Name</span>
                                    <span class="layout_name">{{ item.layout_name }}</span>
                                </li>
                                <li>
                                    <span class="font-weight-bold green">{{ item.symbol }}</span>
                                    <span class="datemodiFy">{{
                                    item.lastModify | moment("MMM Do YYYY, h:mm a")
                                    }}</span>
                                </li>
                                <li class="d-flex">
                                    <button data-title="Apply Layout" class="trashIcon">
                                        <vue-feather size="13px" type="arrow-right" @click="selectLayout(item)" />
                                    </button>
                                    <span class="trashIcon">
                                        <vue-feather size="13px" type="trash-2" @click.stop="removelayout(item.id)" />
                                    </span>
                                </li>
                            </ul>
                            <ul v-if="getLayoutList().length == 0">
                                <li colspan="4" class="noData border-0 h-100"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade shareMedia-Modal"  :class="[{'show' : showPopup == 1}]">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Post Idea</h5>
                        <button type="button" class="btn-close" @click="() => reset()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="postIdeaForm">
                            <Form class="login was-validated" @submit="submitIdea">
                                <div class="form-group">
                                    <label class="form-label">Title</label>
                                   <!--  <input class="form-control" type="text" v-model="form.data.title" /> -->
                                    <Field
                                        type="text"
                                        class="form-control"
                                        name="Title"
                                        rules="required"
                                        v-model="form.data.title"
                                      />
                                      <ErrorMessage
                                        as="paragraph"
                                        name="Title"
                                        class="text-danger mb-0"
                                      />
                                    <!-- <p class="text-danger mb-0">This field is required</p> -->
                                </div>
                                <div class="p-2 my-3 border" v-show="form.data.documentBase64">
                                    <img height="140" style="width: 100%" :src="this.form.data.documentBase64" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label">Description</label>
                                    <!-- <textarea class="form-control" rows="3" v-model="form.data.description"></textarea> -->
                                    <Field
                                        as="textarea"
                                        class="form-control"
                                        name="description"
                                        v-model="form.data.description"
                                      />
                                </div>
                            
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="form-group mt-1">
                                            <label class="form-label">Type</label>
                                            <!-- <select v-model="form.data.type" class="form-control form-select">
                                                <option v-for="type of form.types" :key="type"> {{ type }} </option>
                                            </select> -->
                                            <Field
                                                as="select"
                                                class="form-control"
                                                name="Type"
                                                v-model="form.data.type"
                                                rules="required"
                                            >
                                               <option v-for="type of form.types" :key="type" :value="type"> {{ type }} </option>
                                            </Field>
                                            <ErrorMessage
                                                as="paragraph"
                                                name="Type"
                                                class="text-danger mb-0"
                                              />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="form-group mt-1">
                                            <label class="form-label">Classification</label>
                                            <!-- <select v-model="form.data.classification" class="form-control form-select">
                                                <option v-for="classification of static_vars.classifications"
                                                    :key="classification">
                                                    {{ classification }}
                                                </option>
                                            </select> -->
                                            <Field
                                                as="select"
                                                class="form-control"
                                                name="classification"
                                                v-model="form.data.classification"
                                                rules="required"
                                            >
                                               <option v-for="classification of static_vars.classifications"
                                                    :key="classification" :value="classification">
                                                    {{ classification }}
                                                </option>
                                            </Field>
                                            <ErrorMessage
                                                as="paragraph"
                                                name="classification"
                                                class="text-danger mb-0"
                                              />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="form-group mt-1">
                                            <label class="form-label">Position</label>
                                            <!-- <select v-model="form.data.position" class="form-control form-select">
                                                <option v-for="position of form.positions" :key="position">
                                                    {{ position }}
                                                </option>
                                            </select> -->
                                            <Field
                                                as="select"
                                                class="form-control"
                                                name="position"
                                                v-model="form.data.position"
                                                rules="required"
                                            >
                                               <option v-for="position of form.positions" :key="position" :value="position">
                                                    {{ position }}
                                                </option>
                                            </Field>
                                            <ErrorMessage
                                                as="paragraph"
                                                name="position"
                                                class="text-danger mb-0"
                                              />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h4>Share</h4>
                                    <div class="d-flex justify-content-between">
                                        <label class="customCheckbox">Facebook
                                            <input class="form-check-input" type="checkbox"
                                                v-model="form.data.share_facebook" id="facebook" />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="customCheckbox">Instagram
                                            <input class="form-check-input" type="checkbox"
                                                v-model="form.data.share_instagram" id="instagram" />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="customCheckbox">Twitter
                                            <input class="form-check-input" type="checkbox"
                                                v-model="form.data.share_twitter" id="twitter" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <label class="customCheckbox">Make this idea private
                                    <input class="form-check-input" type="checkbox" :value="1" v-model="form.data.is_public"
                                        id="private" />
                                    <span class="checkmark"></span>
                                </label>
                            <div class="form-group">
                              <button class="grd_btn w-100">Submit</button>
                            </div>
                        </Form>
                        </div>
                    </div>
                    <!-- <div class="modal-footer border-0 pt-0 justify-content-start">
                        <a href="javascript:void(0)" @click="() => submitIdea()" type="button"
                            class="grd_btn">Submit</a>
                    </div> -->
                </div>
            </div>
        </div>
        <div
          class="modal fade"
          :class="[{'show' : showPopup == 0}]"
        >
          <div class="modal-dialog modal-dialog-centered max-600">
            <div class="modal-content">
              <div class="modal-header pb-0 justify-content-end border-0">
                <a
                  href="javascript:void(0)"
                  class="close"
                  @click ="showPopup = -1;loginform = {email: '',password:''}"
                >
                  <vue-feather type="x"></vue-feather>
                </a>
              </div>
              <div class="modal-body">
                <div class="loginPortfolio h-100">
                  <h3 class="text-center mb-5">Log into Portfolios</h3>
                  <Form class="login was-validated" @submit="submit">
                    <!--Click submit add class "was-validated"-->
                    <div class="form-group">
                      <label>Email Address</label>
                        <Field
                            type="text"
                            class="form-control"
                            name="Email"
                            rules="required|email"
                            v-model="loginform.email"
                          />
                        <ErrorMessage
                            as="paragraph"
                            name="Email"
                            class="text-danger mb-0"
                          />
                    </div>
                    <div class="form-group pb-2">
                      <label>Password</label>
                      <Field
                        type="password"
                        class="form-control"
                        name="Password"
                        rules="required"
                        v-model="loginform.password"
                      />
                      <ErrorMessage
                        as="paragraph"
                        name="Password"
                        class="text-danger mb-0"
                      />
                    </div>
                   <!--  <div class="form-group">
                      <a
                        href="javascript:void(0)"
                        class="grd_btn w-100"
                        data-bs-dismiss="modal"
                        >LOG IN
                      </a>
                    </div> -->
                    <div class="form-group">
                      <button class="grd_btn w-100">LOG IN</button>
                    </div>
                  </Form>
                  <div class="or text-center mb-4 d-none">
                    <p class="mb-0 d-inline-flex">OR</p>
                  </div>
                  <!-- <div class="socialShare d-none">
                    <a
                      href="javascript:void(0)"
                      class="border-btn d-flex align-items-center justify-content-center"
                      ><img
                        class="me-3"
                        src="assets/images/google.svg"
                        alt="Google"
                        title="Continue with Google"
                      />Continue with Google</a
                    >
                    <a
                      href="javascript:void(0)"
                      class="border-btn d-flex align-items-center justify-content-center"
                      ><img
                        class="me-3"
                        src="assets/images/apple.svg"
                        alt="Apple"
                        title="Continue with Apple"
                      />Continue with Apple</a
                    >
                    <a
                      href="javascript:void(0)"
                      class="border-btn d-flex align-items-center justify-content-center"
                      ><img
                        class="me-3"
                        src="assets/images/facebook-cir.svg"
                        alt="Facebook"
                        title="Continue with Facebook"
                      />Continue with Facebook</a
                    >
                  </div> -->
                  <div class="register text-center pb-4">
                   Don’t have an account?
                    <router-link to="/register" class="green underline"
                      >Sign Up Here</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
// import * as _ from "@/chartiq";
// import * as Stx from "../../../public/assets/js/chartiq/stx";
import { useMainStore } from "@/store";
import * as __ from "@/quoteFeedSimulator";
import { Const } from "@/plugins/vue-provider-cache/const.mod";
// var Stx = require("@/stx");
import math from "@/math";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "Chart-page",
    setup() {
        // window.___ = _;
        // window.__ = __;
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            taskTab: "EconomicCalendar",
            // chartType: 'USD-BTC',
            form: {
                data: {
                    title: "",
                    description: "",
                    type: "",
                    classification: "",
                    position: "",
                    share_facebook: 0,
                    share_instagram: 0,
                    share_twitter: 0,
                    is_public: 0,
                    symbol: "",
                    time_interval: "",
                    document: "",
                    documentBase64: "",
                    category : ""
                },
                types: ["Trade Idea", "Educational", "Beyond Technical Analysis"],
                positions: ["Buy", "Sell", "Neutral"],
            },

            pageLoader: false,
            upButton: false,
            downButton: true,
            buySellType: "buy",

            loading: 1,
            tradeBuySell: false,

            stxx: "",
            loadChart: "EURUSD",
            mn: "EURUSD",
            intervalPeriod: 60,
            intervalTitle: {
                "1M": "1 Minute",
                "5M": "5 Minute",
                "15M": "15 Minute",
                "1H": "1 Hour",
                "4H": "4 Hour",
                "1D": "1 Day",
                "1W": "1 Week",
                "1MN": "1 Month",
            },
            chartInterval: [
                { unit: 1, name: "minute", data: "1M" },
                { unit: 5, name: "minute", data: "5M" },
                { unit: 15, name: "minute", data: "15M" },
                { unit: 1, name: "hour", data: "1H" },
                { unit: 4, name: "hour", data: "4H" },
                { unit: 1, name: "day", data: "1D" },
                { unit: 1, name: "week", data: "1W" },
                { unit: 1, name: "month", data: "1MN" },
            ],
            defaultChartInterval: "5M",
            search: "",
            searchPopUp: false,
            ShowSearchPopup: false,
            ChartValues: { Open: "", High: "", Low: "", Close: "" },
            loginform: {
                email: "",
                password: "",
            },
            // fullscreen: false,
            // tools: [
            //   /* "",*/
            //   "crossline",
            //   "trendline",
            //   "fibfan",
            //   "freeform",
            //   "annotation",
            //   "fibonacci",
            //   "gannfan",
            //   "average",
            //   "line",
            //   "",
            // ],
            tools: [
                "annotation",
                "average",
                //"BaseTwoPoint",
                "callout",
                "channel",
                "continuous",
                "crossline",
                //"elliottwave",
                "ellipse",
                "fibarc",
                "fibfan",
                "fibonacci",
                "fibprojection",
                "fibtimezone",
                "freeform",
                "gannfan",
                "gartley",
                "horizontal",
                "line",
                "measure",
                "pitchfork",
                "quadrant",
                "ray",
                "rectangle",
                "regression",
                "retracement",
                "segment",
                //"shape",
                "speedarc",
                "speedline",
                "timecycle",
                "tirone",
                "trendline",
                "vertical",
                //"volumeprofile",
            ],
            chartType: "candle",
            chartTypelist: [
                { type: "candle", value: "Candle", class: "icon-candle" },
                {
                    type: "hollow_candle",
                    value: "Hollow Candle",
                    class: "hollow-candle",
                },
                { type: "mountain", value: " Mountain", class: "icon-area" },
                { type: "colored_line", value: "Line", class: "icon-line" },
                { type: "colored_bar", value: "Bar", class: "icon-bar" },
                {
                    type: "baseline_delta",
                    value: "Baseline Delta",
                    class: "icon-baseLine",
                },
                // {type:'histogram', value:'Histogram', class:''},
                // {type:'wave', value:'Wave', class:''}
            ],
            searchStudy: "",
            studiesAddedList: [],
            studies_List: {},
            alertTab: false,
            studyDialogOpened: false,
            chartReady: false,
            toolSelected: "",
            ticks: 70,
            showLine: false,
            ShowUploads: false,
            showCreateLayout: false,
            saveAsLayout: false,
            new_layout_name: "",
            submitted: false,
            layoutSelected: "",
            saveCurrentlayout: false,
            renamelayout: {},
            studdyActive: false,
            errorImage: [],
            watchId: null,
            showIsinsList: true,
            search: "",
            showPopup : -1
        };
    },
    watch: {
        loadChart(val) {
            this.changeNewChart();
            this.chartReady = true;
            if (localStorage.getItem("watchId")) {
                let watchId = localStorage.getItem("watchId");
                this.watchId = watchId == "null" ? null : watchId;
            } else {
                this.watchId = null;
            }
        },
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    methods: {
        submit() {
            let form ={
                'email' : this.loginform.email,
                'password' : this.loginform.password,
                'from' : 'PostIdea'
            }
          this.store.login(form, true,this);
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        },
        initIdea() {
            if(Object.keys(this.store.user).length > 0 && this.store.user.profile){
                this.form.data.symbol = this.loadChart;
                this.form.data.category = this.instruments[this.loadChart].other.type
                this.form.data.documentBase64 = this.stxx.chart.canvas.toDataURL();
                this.form.data.document = this.dataURLtoFile(
                    this.form.data.documentBase64,
                    "chart_idea.png"
                );
                this.form.data.time_interval = this.defaultChartInterval;
                this.showPopup = 1
            }else{
                this.showPopup = 0
            }
        },
        submitIdea() {
            let d = new FormData();
            d.append("user_id", this.store.user.profile.user_id);
            d.append(
                "tags",
                `${this.form.data.symbol},${this.form.data.time_interval},${this.form.data.position}`
            );
            for (let key in this.form.data) {
                if (key != "documentBase64") {
                    if (
                        key == "is_public" ||
                        key == "share_facebook" ||
                        key == "share_instagram" ||
                        key == "share_twitter"
                    ) {
                        d.append(key, this.form.data[key] ? 1 : 0);
                    } else {
                        d.append(key, this.form.data[key]);
                    }
                }
            }
            this.store.callCreateTradeIdea(d, true,this);
        },
        reset(){
            this.showPopup = -1
            this.form =  {
                data: {
                    title: "",
                    description: "",
                    type: "",
                    classification: "",
                    position: "",
                    share_facebook: 0,
                    share_instagram: 0,
                    share_twitter: 0,
                    is_public: 0,
                    symbol: "",
                    time_interval: "",
                    document: "",
                    documentBase64: "",
                    category : "",
                },
                types: ["Trade Idea", "Educational", "Beyond Technical Analysis"],
                positions: ["Buy", "Sell", "Neutral"],
            }
        },
        formatfloat(num) {
            // return num
            num = parseFloat(num);
            try {
                return math.format(num, { notation: "fixed" }) || "";
            } catch (e) {
                try {
                    return num.toFixed(8);
                } catch (e) {
                    return "";
                }
            }
        },
        addRemFev(item) {
            let _that = this;
            let watchId = null;
            if (localStorage.getItem("watchId")) {
                watchId = localStorage.getItem("watchId");
                this.watchId = watchId == "null" ? null : watchId;
            }
            let itemData = { isins: [item], id: watchId };
            let fevList = [];
            this.watchListData.forEach((val, index) => {
                if (val._id === _that.watchId) {
                    fevList = val.is_fav ? val.is_fav : [];
                }
            });
            if (fevList.includes(item)) {
                fevList = fevList.filter((val) => val != item);
                this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].seeders[
                    "S_PF_PF"
                ].removeFavorite(itemData);
            } else if (fevList.length <= 20) {
                fevList.push(item);
                this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].seeders[
                    "S_PF_PF"
                ].addFavorite(itemData);
            } else if (this.subList.length >= 20) {
                this.displayError(
                    "you can add maximum 20 pair's in fevorite List",
                    "error"
                );
            }
            let watchListData = [...this.watchListData];
            watchListData.forEach((val, index) => {
                if (val._id == _that.watchId) {
                    watchListData[index].is_fav = fevList;
                }
            });
            this.$store.commit("/SET_WATCH_LIST", watchListData);
        },
        handleImgErr(val) {
            if (!this.errorImage.includes(val)) {
                this.errorImage.push(val);
            }
        },
        layoutName() {
            return (
                (this.getCurrentLayout().slice(0, 10) || "") +
                (this.getCurrentLayout().length > 10 ? "..." : "")
            );
        },
        renameClick() {
            if (this.layoutSelected) {
                this.renamelayout = this.getLayoutList().filter(
                    (item) => this.layoutSelected == item.id
                )[0];
            } else {
                this.saveLayoutClick();
            }
        },
        saveRenamelayout() {
            let _that = this;
            let totalData = _that.getRawData();
            let getIndex = totalData.view_mode.chart_layout.findIndex(
                (item) => _that.layoutSelected == item.id
            );
            totalData.view_mode.chart_layout[getIndex]["layout_name"] =
                this.renamelayout.layout_name;
            totalData.view_mode.chart_layout[getIndex]["lastModify"] = Date.now();
            _that._runSetRawObject({
                globalParams: { setting: totalData },
                localParams: { parameter: this },
            });
        },
        saveAsClick() {
            if (this.layoutSelected) {
                let data = this.getLayoutList().filter(
                    (item) => this.layoutSelected == item.id
                )[0];
                this.new_layout_name = (data.layout_name + " Copy").slice(0, 20);
                this.saveAsLayout = true;
                this.showCreateLayout = true;
            } else {
                this.saveLayoutClick();
            }
        },
        getCurrentLayout() {
            try {
                return this.getLayoutList().filter(
                    (item) => this.layoutSelected == item.id
                )[0].layout_name;
            } catch (e) {
                return "";
            }
        },
        getLayoutList() {
            try {
                return this.getRawData().view_mode.chart_layout;
            } catch (e) {
                return [];
            }
        },
        rawObjectSaved() {
            //
            if (this.showCreateLayout) {
                this.submitted = true;
                this.saveAsLayout = true;
                this.showCreateLayout = "";
                this.new_layout_name = "";
                this.displayError("New Layout Created successfully", "success");
            }
            if (this.saveCurrentlayout) {
                this.displayError("Layout saved successfully", "success");
                this.saveCurrentlayout = false;
            }
            if (this.ShowUploads) {
                this.displayError("Layout Removed successfully", "success");
                this.ShowUploads = false;
            }
            if (Object.keys(this.renamelayout).length > 0) {
                this.displayError("Layout Renamed successfully", "success");
                this.renamelayout = {};
            }
        },
        saveLayoutClick() {
            var drawingList = this.stxx.exportDrawings();
            var studiesList = this.stxx.exportLayout();
            if (drawingList.length || Object.keys(studiesList.studies).length > 0) {
                if (this.layoutSelected) {
                    this.savelayout();
                } else {
                    this.showCreateLayout = true;
                }
            } else {
                this.displayError("No Indicator or Drawing applied yet.", "error");
            }
        },
        closeCreate() {
            this.showCreateLayout = false;
            this.saveAsLayout = false;
            this.new_layout_name = "";
        },
        createNewlayout() {
            this.submitted = true;
            let _that = this;
            _that.$validator.validate().then((valid) => {
                if (valid) {
                    var drawingList = _that.stxx.exportDrawings();
                    var studiesList = _that.stxx.exportLayout();
                    if (
                        drawingList.length ||
                        Object.keys(studiesList.studies).length > 0
                    ) {
                        let data = {
                            id: Date.now(),
                            layout_name: _that.new_layout_name,
                            symbol: _that.loadChart,
                            mn: _that.mn,
                            description: _that.instruments[_that.loadChart].other.Description,
                            layout: {},
                            lastModify: Date.now(),
                        };
                        if (drawingList.length > 0) {
                            data.layout["drawingList"] = drawingList;
                        } // to save drawing
                        if (Object.keys(studiesList.studies).length > 0) {
                            data.layout["studiesList"] = studiesList;
                        } // to save studies

                        let totalData = _that.getRawData();
                        totalData.view_mode.chart_layout.push(data);

                        _that.layoutSelected = data.id;
                        _that._runSetRawObject({
                            globalParams: { setting: totalData },
                            localParams: { parameter: this },
                        });
                    }
                }
            });
        },
        savelayout() {
            let _that = this;
            var drawingList = _that.stxx.exportDrawings();
            var studiesList = _that.stxx.exportLayout();
            if (drawingList.length || Object.keys(studiesList.studies).length > 0) {
                let totalData = _that.getRawData();
                let getIndex = totalData.view_mode.chart_layout.findIndex(
                    (item) => _that.layoutSelected == item.id
                );

                if (drawingList.length > 0) {
                    // to save drawing
                    totalData.view_mode.chart_layout[getIndex].layout["drawingList"] =
                        drawingList;
                } else {
                    totalData.view_mode.chart_layout[getIndex].layout["drawingList"] =
                        null;
                }

                if (Object.keys(studiesList.studies).length > 0) {
                    // to save studies
                    totalData.view_mode.chart_layout[getIndex].layout["studiesList"] =
                        studiesList;
                } else {
                    totalData.view_mode.chart_layout[getIndex].layout["studiesList"] =
                        null;
                }
                totalData.view_mode.chart_layout[getIndex]["lastModify"] = Date.now();
                this.saveCurrentlayout = true;
                _that._runSetRawObject({
                    globalParams: { setting: totalData },
                    localParams: { parameter: this },
                });
            }
        },
        removelayout(ID) {
            let _that = this;
            let totalData = _that.getRawData();
            totalData.view_mode.chart_layout =
                totalData.view_mode.chart_layout.filter((item) => item.id != ID);
            if ((this.layoutSelected = ID)) {
                this.layoutSelected = "";
            }
            _that._runSetRawObject({
                globalParams: { setting: totalData },
                localParams: { parameter: this },
            });
        },
        selectLayout(val) {
            if (this.loadChart != val.symbol) {
                this.layoutSelected = val.id;
                this.$emit("input", { key: val.symbol, mn: val.mn });
                this.ShowUploads = false;
            } else if (this.layoutSelected != val.id) {
                this.layoutSelected = val.id;
                this.ShowUploads = false;
                this.applyloadedLayout();
            }
        },
        applyloadedLayout() {
            const _that = this;
            try {
                for (var studyInstanceName in _that.stxx.layout.studies) {
                    var sd = _that.stxx.layout.studies[studyInstanceName];
                    //CIQ.Studies.removeStudy(_that.stxx, sd);
                }
            } catch (e) { }
            if (this.layoutSelected) {
                let data = _that
                    .getLayoutList()
                    .filter((item) => _that.layoutSelected == item.id)[0];
                if (data.symbol == _that.loadChart) {
                    setTimeout(function () {
                        try {
                            if (data && data.layout) {
                                _that.showLine = false;
                                if (data.layout["studiesList"]) {
                                    var importOptions = {
                                        managePeriodicity: true,
                                        preserveTicksAndCandleWidth: true,
                                    };
                                    _that.stxx.importLayout(
                                        data.layout["studiesList"],
                                        importOptions
                                    );
                                    _that.chartType = data.layout["studiesList"].chartType;
                                    /*if(!data.layout.hasOwnProperty('drawing') ){
                                                          _that.showLine = true;
                                                      }*/
                                }
                                if (data.layout["drawingList"]) {
                                    // _that.stxx.reconstructDrawings(data.layout['drawingList'])
                                    _that.stxx.importDrawings(data.layout["drawingList"]);
                                    _that.stxx.changeVectorType("");
                                    let interval = setInterval(function () {
                                        if (_that.stxx.exportDrawings().length == 0) {
                                            // _that.stxx.reconstructDrawings(data.layout['drawingList'])
                                            _that.stxx.importDrawings(data.layout["drawingList"]);
                                            _that.stxx.changeVectorType("");
                                        } else {
                                            // _that.showLine = true;
                                            clearInterval(interval);
                                        }
                                    }, 100);
                                }
                            } else {
                                _that.changeChartType("candle");
                                // _that.showLine = true;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }, 300);
                } else {
                    _that.layoutSelected = "";
                }
            }
        },
        changeCrosshair() {
            if (this.stxx.layout.crosshair) {
                this.stxx.layout.crosshair = true;
            } else {
                this.stxx.layout.crosshair = false;
            }
        },
        getCrosshair() {
            try {
                return this.stxx.layout.crosshair;
            } catch (e) {
                return false;
            }
        }, // this.stxx.layout.crosshair = true;
        create_image() {
            var stx = this.stxx;
            var link = document.createElement("a");
            link.download = "filename.jpeg";
            link.href = stx.chart.canvas.toDataURL("image/png");
            link.click();
        },
        dismissDialog() {
            STX.DialogManager.dismissDialog();
            this.studyDialogOpened = false;
        },
        createStudy() {
            /*// var div = document.getElementById('studyDialog')
                  const div = this.$refs.studyDialog
                  // console.log('div-ref',div)
                  var a = STX.Studies.go(div, this.stxx);	// Note, please add stxx as second parameter in order to translate studies
                  // console.log('STX.Studies.go--->',a)
                  // console.log('STX.Studie--->',CIQ.Studies.studyLibrary[div.study])
                  // console.log('a--------------->',a)
                  this.applyStudy(a,div.study)
                  // console.log('2',div.study)
                  // STX.Studies.go(Stx.$$("studyDialog"), this.stxx);  // Note, please add stxx as second parameter in order to translate studies*/
            var a = STX.Studies.go($$("studyDialog"), this.stxx);
            if (a.inputs) {
                this.applyStudy(a, $$("studyDialog").study);
            }
            STX.DialogManager.dismissDialog();
            this.studyDialogOpened = false;
        },
        applyStudy(value, name) {
            for (var type in value) {
                // console.log(type,'=>')
                for (var std in value[type]) {
                    // console.log(std,':- ',value[type][std])
                    CIQ.Studies.studyLibrary[name][type][std] = value[type][std];
                }
            }
            // CIQ.Studies.removeStudy(this.stxx, name)
            CIQ.Studies.addStudy(this.stxx, name); //, null, null, { display: name }
            this.studdyActive = true;
            // var params = { stx: stx, sd: sd, inputs: inputs, outputs: outputs, parameters: parameters };
        },
        addStudie(study) {
            if (!this.stxx || !this.stxx.chart.dataSet) return;
            if (
                CIQ.Studies.studyLibrary[study] &&
                CIQ.Studies.studyLibrary[study].name
            ) {
                $$("studyDialog").querySelectorAll(".title")[0].innerHTML =
                    this.stxx.translateIf(CIQ.Studies.studyLibrary[study].name);
            } else {
                $$("studyDialog").querySelectorAll(".title")[0].innerHTML =
                    study.capitalize();
            }
            // console.log("CIQ", CIQ);
            // console.log("STX", STX);
            STX.Studies.studyDialog(this.stxx, study, $$("studyDialog"));
            var delay = STX.ipad ? 400 : 0; // so that ipad doesn't register taps from menu selection on dialog
            setTimeout(function () {
                STX.DialogManager.displayDialog("studyDialog");
                this.studyDialogOpened = true;
            }, delay);
        },
        removeStudy(val) {
            var study;
            for (var studyInstanceName in this.stxx.layout.studies) {
                var sd = this.stxx.layout.studies[studyInstanceName];
                if (sd.type == val) {
                    study = sd;
                }
            }
            if (study) {
                CIQ.Studies.removeStudy(this.stxx, study);
            }
            this.studiesAddedList = this.studiesAddedList.filter(
                (data) => data != val
            );
        },
        ChartName(chartType) {
            return this.chartTypelist.filter((val) => val.type == chartType)[0].value; //value
        },
        ChartClass(chartType) {
            return this.chartTypelist.filter((val) => val.type == chartType)[0].class; //value
        },
        changeChartType(type) {
            this.chartType = type;
            this.stxx.setChartType(type);
        },
        openFullscreen() {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                var elem = document.getElementById("charScreeen");
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    /* IE11 */
                    elem.msRequestFullscreen();
                }
            }
        },
        drop(ev) {
            var data = ev.dataTransfer.getData("marketData");
            if (data) {
                this.$emit("input", JSON.parse(data));
            }
        },
        enableDrawingTool: function (tool) {
            if (tool == "") {
                if (this.stxx.drawingObjects.length != 0) {
                    this.stxx.removeDrawing(
                        this.stxx.drawingObjects[this.stxx.drawingObjects.length - 1]
                    );
                }
                this.toolSelected = "";
                this.stxx.changeVectorType("");
            } else if (this.toolSelected == tool) {
                this.toolSelected = "";
                this.stxx.changeVectorType("");
            } else {
                this.toolSelected = tool;
                this.stxx.changeVectorType(tool);
            }
            // this.stxx.currentVectorParameters.pattern = "solid";
        },
        displayError(getMessage, type) {
            this.$notify({
                group: "foo",
                type: type && type == null ? "error" : type,
                text: getMessage,
                duration: 3000,
                closeOnClick: true,
            });
        },
        changeSymbol(isin) {
            this.loadChart = isin.other.isinid;
            this.mn = isin.other.mn;
            this.changeNewChart();
        },
        changeNewChart() {
            this.showLine = false;
            const _her = this;
            setTimeout(function () {
                try {
                    _her.loading = 1;
                    let chartName = _her.mn ? _her.mn : _her.loadChart;
                    // if (this.defaultChartInterval == "1M") {
                    //     this.stxx.setMaxTicks(300);
                    // }
                    _her.stxx.newChart(chartName, null, null, function () {
                        _her.loading = 0;
                        _her.applyloadedLayout();
                    });
                } catch (e) {
                    // console.log(e)
                }
            }, 300);
        },
        changeInterval(value) {
            if (this.defaultChartInterval != value.data) {
                if (this.defaultChartInterval == value.data) {
                    return;
                }
                this.defaultChartInterval = value.data;
                var intval = value.unit;
                var tUnit = value.name;
                let valInter = 1;
                /*if (tUnit == "hour") {
                    intval = 60;
                    tUnit = "minute";
                }*/

                // if (tUnit == "1W") {
                //     intval = 7;
                //     tUnit = "day";
                // }

                // if (tUnit == "1MN") {
                //     intval = 30;
                //     tUnit = "day";
                // }

                /*if (value.name == "minute") {
                    this.stxx.setMaxTicks(300);
                    this.ticks = 300;
                }*/

                this.stxx.setMaxTicks(300);

                // console.log('data',{period:1, interval:intval, timeUnit:tUnit})
                // this.stxx.setPeriodicity({period:intval, interval:intval, timeUnit: tUnit});
                this.stxx.setPeriodicity({interval:intval, period: tUnit, timeUnit: tUnit});

                // if(value.name == "minute"){
                //     this.stxx.setPeriodicity(intval,tUnit,2,'hour');
                // }if(value.name == "hour"){
                //     console.log(value)
                //     this.stxx.setPeriodicity(intval,tUnit,1,'day');
                // }if(value.name == "day"){
                //     this.stxx.setPeriodicity(intval,tUnit,1,'month');
                // }else{
                //     this.stxx.setPeriodicity({
                //         period: intval,
                //         interval: tUnit,
                //         timeUnit: tUnit,
                //     });
                // }
            }
        },
        chartColor() {
            try {
                let text = "",
                    grid = "";
                // if (this.$root.$children[0].theme) {
                //   // text = "#ffffff";
                //   // grid = "#273762";
                //   text = "#c7c7c7";
                //   grid = "#EEEDED";
                // } else {
                //   text = "#c7c7c7";
                //   grid = "#EEEDED";
                // }
                text = "#c7c7c7";
                grid = "#EEEDED";
                this.stxx.setStyle("stx_xaxis_dark", "color", text);
                this.stxx.setStyle("stx_xaxis", "color", text);
                this.stxx.setStyle("stx_yaxis_dark", "color", text);
                this.stxx.setStyle("stx_yaxis", "color", text);
                this.stxx.setStyle("stx-panel-control", "color", text);

                this.stxx.setStyle("stx_grid", "color", grid);
                this.stxx.setStyle("stx_grid_dark", "color", grid);
                //dee2e6 text
                //29324F grid
            } catch (e) { }
        },
        /*initalizeChart: function () {
          // this.stxx = new STXChart({container:$$$(".chartContainer"), layout:{"candleWidth": 16, "crosshair":true}});
          this.stxx = new window.CIQ.ChartEngine({
            container: window.$$$(".chartContainer"),
            layout: { candleWidth: 16, crosshair: true },
          });
          // this.stxx = new STXChart( { container: window.$$$(".chartContainer"), layout:{"candleWidth": 16, "crosshair":true} } );
          if (this.stxx.controls.chartControls) {
            // stxx.controls.chartControls.style.display="block";        // use this line to enable the controls
            this.stxx.controls.chartControls.style.display = "none"; // use this line to disable the controls
          }
          this.stxx.chart.xAxis.displayGridLines = true;
          this.stxx.chart.yAxis.displayGridLines = true;

          // for hollow candle
          this.stxx.setStyle("stx_hollow_candle_up", "color", "#10BD72");
          this.stxx.setStyle("stx_hollow_candle_down", "color", "#F25767");

          // for mountain
          this.stxx.setStyle("stx_mountain_chart", "border-top-color", "#10BD72");
          // for line chart
          this.stxx.setStyle("stx_line_up", "color", "#10BD72");
          this.stxx.setStyle("stx_line_down", "color", "#F25767");
          // for bar chart
          this.stxx.setStyle("stx_bar_up", "color", "#10BD72");
          this.stxx.setStyle("stx_bar_down", "color", "#F25767");
          // for base line delta chart
          this.stxx.setStyle("stx_baseline_up", "color", "#10BD72");
          this.stxx.setStyle("stx_baseline_down", "color", "#F25767");

          this.stxx.setChartType("candle");
          this.stxx.setStyle("stx_candle_up", "border-left-color", "#66000000");
          this.stxx.setStyle("stx_candle_down", "border-left-color", "#66000000");
          this.stxx.setStyle("stx_candle_up", "color", "#10BD72");
          this.stxx.setStyle("stx_candle_down", "color", "#F25767");
          this.stxx.setStyle("stx_candle_shadow_up", "color", "#10BD72");
          this.stxx.setStyle("stx_candle_shadow_down", "color", "#F25767");
          this.chartColor();

          this.stxx.chart.xAxis.axisType = "ntb";
          this.stxx.chart.yAxis.goldenRatioYAxis = true;
          this.stxx.chart.yAxis.position = "right";
          this.stxx.allowEquations = false;
          // this.stxx.yaxisLeft = 0;
          // this.stxx.chart.yAxis.justifyRight = true;
          // this.stxx.allowEquations = true;
          // this.stxx.preferences.whitespace = 0;
          // this.stxx.chart.yAxis.yaxisLabelStyle = "rect";
          this.stxx.attachQuoteFeed(__.quoteFeedSimulator, {
            refreshInterval: this.intervalPeriod,
            bufferSize: 60,
          });
          this.stxx.setMarketFactory(window.CIQ.Market.Symbology.factory);
          // this.stxx.setMarketFactory(STX.Market.Symbology.factory);
          // this.stxx.chart.yAxis.initialMarginTop=60;

          //this.stxx.setMaxTicks(60);
          //this.stxx.cleanupGaps = "carry";
          this.stxx.setPeriodicity({ period: 1, interval: 1, timeUnit: "day" });
          const _her = this;
          let chartName = this.mn ? this.mn : this.loadChart;
          this.stxx.newChart(chartName, null, null, function () {
            CIQ.Drawing.prototype.dragToDraw = true;
            CIQ.ChartEngine.prototype.append("draw", function () {
              // _her.drawLine(1.3568,0)
              // _her.drawLine(1.3568,0)
            });
            _her.loading = 0;
            _her.chartReady = true;
          });
          // this.stxx.setMaxTicks(70);
          // this.ticks = 300
          // CIQ.Studies.addStudy(this.stxx, "vol undr");
          this.stxx.chart.allowScrollFuture = false;
          this.stxx.chart.yAxis.width = 60;
          this.stxx.calculateYAxisPositions();
          this.stxx.draw();
          // this.stxx.preferences.dragging = false;
          this.showLine = true;
          this.stxx.addEventListener("drawing", function () {
            _her.stxx.changeVectorType(null);
            _her.toolSelected = "-";
          });
          this.stxx.addEventListener("tap", function (tapObject) {
            // console.log('--->',tapObject)
            var msg = "tap event at x: " + tapObject.x + " y: " + tapObject.y;
            var bar = this.barFromPixel(this.cx);
            if (this.chart.dataSegment[bar]) {
              msg += " Date:" + this.chart.dataSegment[bar].DT;
              msg += " Close:" + this.chart.dataSegment[bar].Close;
              msg += "Price:" + this.priceFromPixel(tapObject.y);
            }
            // console.log('----------------->',this.priceFromPixel(tapObject.y))
            // alert(msg);
            if (this.anyHighlighted) {
                for (let n in this.chart.seriesRenderers) {
                    let r = this.chart.seriesRenderers[n];
                    for (let j = 0; j < r.seriesParams.length; j++) {
                        series = r.seriesParams[j];
                        if (series.highlight) {
                            let bar = this.barFromPixel(tapObject.x);
                            if (this.chart.dataSegment[bar]) {
                                // Replace console.log with your required logic as needed.
                                console.log("Tap event at pixel x: " + tapObject.x + " y: " + tapObject.y);
                                console.log(
                                    "Price:",
                                    this.priceFromPixel(tapObject.y),
                                    " Date: ",
                                    this.chart.dataSegment[bar].DT
                                );
                                console.log("Series Details: ", JSON.stringify(series));
                            }
                        }
                    }
                }
            }
          });
          this.stxx.callbacks.studyOverlayEdit = function (params) {
            var stx = params.stx;
            var sd = params.sd;
            STX.DialogManager.displayDialog("contextMenu");
            if (
              CIQ.Studies.studyLibrary[sd.type] &&
              CIQ.Studies.studyLibrary[sd.type].name
            ) {
              Stx.$$("contextMenu").querySelectorAll(".title")[0].innerHTML =
                _her.stxx.translateIf(CIQ.Studies.studyLibrary[sd.type].name);
            } else {
              Stx.$$("contextMenu").querySelectorAll(".title")[0].innerHTML =
                sd.type.capitalize();
            }
            if (
              CIQ.Studies.studyLibrary[sd.type] &&
              CIQ.Studies.studyLibrary[sd.type].name
            ) {
              Stx.$$("studyDialog").querySelectorAll(".title")[0].innerHTML =
                _her.stxx.translateIf(CIQ.Studies.studyLibrary[sd.type].name);
            } else {
              Stx.$$("studyDialog").querySelectorAll(".title")[0].innerHTML =
                sd.type.capitalize();
            }
            var edit = Stx.$$$(".stx-edit", Stx.$$("contextMenu"));
            var remove = Stx.$$$(".stx-delete", Stx.$$("contextMenu"));
            STX.safeClickTouch(edit, function(){
                STX.DialogManager.dismissDialog();
                STX.Studies.studyDialog(stx, sd.type, Stx.$$("studyDialog"), params);
                STX.DialogManager.displayDialog("studyDialog");
            });
            STX.safeClickTouch(remove, function () {
              STX.DialogManager.dismissDialog();
              STX.Studies.removeStudy(stx, sd);
              var closeIcon=document.querySelectorAll("#menuWrapperStudies #" + sd.type.replace(" ","-"));
              for(var c=0;closeIcon && c<closeIcon.length;c++){
                  STX.clearSafeClickTouches(closeIcon[c]);
                  closeIcon[c].style.display="";
              }
            });
            // STX.DialogManager.displayDialog("contextMenu");
          };
          this.stxx.callbacks.studyPanelEdit = function (params) {
              var stx=params.stx;
              var sd=params.sd;
              if(CIQ.Studies.studyLibrary[sd.type] && CIQ.Studies.studyLibrary[sd.type].name){
                  Stx.$$("studyDialog").querySelectorAll(".title")[0].innerHTML=_her.stxx.translateIf(CIQ.Studies.studyLibrary[sd.type].name);
              }else{
                  Stx.$$("studyDialog").querySelectorAll(".title")[0].innerHTML=sd.type.capitalize();
              }
              STX.Studies.studyDialog(stx, sd.type, Stx.$$("studyDialog"), params);
              STX.DialogManager.displayDialog("studyDialog");
          }
          this.applyloadedLayout();
          // this.stxx.ThemeManager.builtInThemes={
          //         "White":"Light",
          //         "Black":"Dark"
          // };
          // this.stxx.ThemeManager.setThemes({"enabledTheme":"Light","customThemes":{}}, stxx);

          CIQ.Studies.studyLibrary["Plot High Low"] = {
                    "seriesFN": CIQ.Studies.displaySeriesAsHistogram,
                    inputs: {
                      "HistogramType": "clustered"
                    },
                    outputs: {
                      "High": "#10BD72",
                      "Low": "#F25767"
                    },
                    parameters:{"widthFactor":0.5},
                };
          // CIQ.Studies.addStudy(this.stxx, "Plot High Low");
          // CIQ.Studies.addStudy(this.stxx, "volume");
          // this.stxx.allowSideswipe = true;
          // console.log('this.stxx',this.stxx)
          // console.log(CIQ.Studies.studyLibrary)
          for(var key in CIQ.Studies.studyLibrary){
        		this.studies_List[key] = CIQ.Studies.studyLibrary[key].name
                }
                console.log(this.studies_List)
        },*/
        initalizeChart(){
            this.stxx = new CIQ.ChartEngine({
                container: $$$(".chartContainer"),
                layout: { candleWidth: 16, crosshair: true },
            });
            if (this.stxx.controls.chartControls) {
                this.stxx.controls.chartControls.style.display = "none";
            }
            this.stxx.chart.xAxis.displayGridLines = true;
            this.stxx.chart.yAxis.displayGridLines = true;

            // for hollow candle
            this.stxx.setStyle("stx_hollow_candle_up", "color", "#10BD72");
            this.stxx.setStyle("stx_hollow_candle_down", "color", "#F25767");

            // for mountain
            this.stxx.setStyle("stx_mountain_chart", "border-top-color", "#10BD72");
            // for line chart
            this.stxx.setStyle("stx_line_up", "color", "#10BD72");
            this.stxx.setStyle("stx_line_down", "color", "#F25767");
            // for bar chart
            this.stxx.setStyle("stx_bar_up", "color", "#10BD72");
            this.stxx.setStyle("stx_bar_down", "color", "#F25767");
            // for base line delta chart
            this.stxx.setStyle("stx_baseline_up", "color", "#10BD72");
            this.stxx.setStyle("stx_baseline_down", "color", "#F25767");

            this.stxx.setChartType("candle");
            this.stxx.setStyle("stx_candle_up", "border-left-color", "#66000000");
            this.stxx.setStyle("stx_candle_down", "border-left-color", "#66000000");
            this.stxx.setStyle("stx_candle_up", "color", "#10BD72");
            this.stxx.setStyle("stx_candle_down", "color", "#F25767");
            this.stxx.setStyle("stx_candle_shadow_up", "color", "#10BD72");
            this.stxx.setStyle("stx_candle_shadow_down", "color", "#F25767");
            this.chartColor();

            this.stxx.chart.xAxis.axisType = "ntb";
            this.stxx.chart.yAxis.goldenRatioYAxis = true;
            this.stxx.chart.yAxis.position = "right";
            this.stxx.allowEquations = false;
            this.stxx.attachQuoteFeed(__.quoteFeedSimulator, {
                refreshInterval: this.intervalPeriod,
                bufferSize: 60,
            });
            this.stxx.setMarketFactory(CIQ.Market.Symbology.factory);
            this.stxx.setPeriodicity({ period: 1, interval: 5, timeUnit: "minute" });
            // this.stxx.setPeriodicity(5,'minute',2,'day');

            const _her = this;
            let chartName = this.loadChart;
            this.stxx.newChart(chartName, null, null, function () {
                CIQ.Drawing.prototype.dragToDraw = true;
                CIQ.ChartEngine.prototype.append("draw", function () {
                    // _her.drawLine(1.3568,0)
                    // _her.drawLine(1.3568,0)
                });
                _her.loading = 0;
                _her.chartReady = true;
            });
            this.stxx.setMaxTicks(70);
            // this.ticks = 300
            // CIQ.Studies.addStudy(this.stxx, "vol undr");
            this.stxx.chart.allowScrollFuture = true;
            this.stxx.chart.yAxis.width = 60;
            this.stxx.calculateYAxisPositions();
            this.stxx.draw();
            this.stxx.preferences.dragging = true;
            this.showLine = true;
            this.stxx.addEventListener("drawing", function (event) {
                console.log(event)/*,event.detail,event.detail.tool*/
                _her.stxx.changeVectorType(null);
                _her.toolSelected = "-";
            });
            /*this.stxx.addEventListener("tap", function (tapObject) {
                      var msg = "tap event at x: " + tapObject.x + " y: " + tapObject.y;
                      var bar = this.barFromPixel(this.cx);
                      if (this.chart.dataSegment[bar]) {
                          msg += " Date:" + this.chart.dataSegment[bar].DT;
                          msg += " Close:" + this.chart.dataSegment[bar].Close;
                          msg += "Price:" + this.priceFromPixel(tapObject.y)
                      }
                  });*/
            this.stxx.callbacks.studyOverlayEdit = function (params) {
                var stx = params.stx;
                var sd = params.sd;
                STX.DialogManager.displayDialog("contextMenu");
                if (
                    CIQ.Studies.studyLibrary[sd.type] &&
                    CIQ.Studies.studyLibrary[sd.type].name
                ) {
                    $$("contextMenu").querySelectorAll(".title")[0].innerHTML =
                        _her.stxx.translateIf(CIQ.Studies.studyLibrary[sd.type].name);
                } else {
                    $$("contextMenu").querySelectorAll(".title")[0].innerHTML =
                        sd.type.capitalize();
                }
                if (
                    CIQ.Studies.studyLibrary[sd.type] &&
                    CIQ.Studies.studyLibrary[sd.type].name
                ) {
                    $$("studyDialog").querySelectorAll(".title")[0].innerHTML =
                        _her.stxx.translateIf(CIQ.Studies.studyLibrary[sd.type].name);
                } else {
                    $$("studyDialog").querySelectorAll(".title")[0].innerHTML =
                        sd.type.capitalize();
                }
                // var edit=Stx.$$$(".stx-edit", Stx.$$("contextMenu"));
                var remove = $$$(".stx-delete", $$("contextMenu"));
                /*STX.safeClickTouch(edit, function(){
                            STX.DialogManager.dismissDialog();
                            STX.Studies.studyDialog(stx, sd.type, Stx.$$("studyDialog"), params);
                            STX.DialogManager.displayDialog("studyDialog");
                        });*/
                STX.safeClickTouch(remove, function () {
                    STX.DialogManager.dismissDialog();
                    STX.Studies.removeStudy(stx, sd);
                    /*var closeIcon=document.querySelectorAll("#menuWrapperStudies #" + sd.type.replace(" ","-"));
                              for(var c=0;closeIcon && c<closeIcon.length;c++){
                                  STX.clearSafeClickTouches(closeIcon[c]);
                                  closeIcon[c].style.display="";
                    }*/
                });
                // STX.DialogManager.displayDialog("contextMenu");
            };

            this.stxx.prepend("grabHandle", function() {
                return true;
            });
            /*this.stxx.callbacks.studyPanelEdit = function (params) {
                      var stx=params.stx;
                      var sd=params.sd;
                      if(CIQ.Studies.studyLibrary[sd.type] && CIQ.Studies.studyLibrary[sd.type].name){
                          Stx.$$("studyDialog").querySelectorAll(".title")[0].innerHTML=_her.stxx.translateIf(CIQ.Studies.studyLibrary[sd.type].name);
                      }else{
                          Stx.$$("studyDialog").querySelectorAll(".title")[0].innerHTML=sd.type.capitalize();
                      }
                      STX.Studies.studyDialog(stx, sd.type, Stx.$$("studyDialog"), params);
                      STX.DialogManager.displayDialog("studyDialog");
                  }*/
            this.applyloadedLayout();
        },
        setChartValues(open, high, low, close) {
            this.ChartValues.Open = parseFloat(parseFloat(open || 0).toFixed(5));
            this.ChartValues.High = parseFloat(parseFloat(high || 0).toFixed(5));
            this.ChartValues.Low = parseFloat(parseFloat(low || 0).toFixed(5));
            this.ChartValues.Close = parseFloat(parseFloat(close || 0).toFixed(5));
        },
        drawLine(price, side, pattern) {
            const _her = this;
            // CIQ.ChartEngine.prototype.append("draw", function(){
            // do something here
            const parameters = {
                pattern: pattern, // options: "solid","dashed","dotted"
                lineWidth: 0.4, // select any with for the line
            };
            //99
            // const x = this.stxx.pixelFromDate('2016/02/15', this.stxx.chart);
            const x = _her.stxx.pixelFromDate(
                _her.stxx.chart.dataSet[_her.stxx.chart.dataSet.length - 1].Date,
                _her.stxx.chart
            );
            const y = _her.stxx.pixelFromPrice(
                price,
                _her.stxx.chart.panel,
                _her.stxx.chart.yAxis
            );

            const color = parseInt(side) == 0 ? "red" : "green"; // select any color
            const type = "horizontal"; // don't change this setting since we are interested in drawing a horizontal line only with a _her on the y-axis
            _her.stxx.plotLine(
                x,
                x + 1,
                y,
                y,
                color,
                type,
                _her.stxx.chart.context,
                true,
                parameters
            );

            /************* write a message under the last price ****************/
            // var context = _her.stxx.chart.context;
            /*//write text
                          context.font = '14pt Calibri';
                          context.fillStyle = "white";
                          context.fillText("Open at "+price, x - 150, y + 10);*/
            // })
        },
        scrollUp() {
            $("#studytools").scrollTop($("#studytools").scrollTop() - 40);
        },
        scrollDown() {
            // console.log('scrollTop',$('#studytools')[0].scrollHeight - $('#studytools').outerHeight())
            // $('#studytools').scrollTop($('#studytools')[0].scrollHeight - $('#studytools').outerHeight())
            $("#studytools").scrollTop($("#studytools").scrollTop() + 40);
        },
    },
    computed: {
        checkFev() {
            try {
                let fevList = [];
                let _that = this;
                this.watchListData.forEach((val, index) => {
                    if (val._id === _that.watchId) {
                        fevList = val.is_fav ? val.is_fav : [];
                    }
                });
                return fevList.includes(this.loadChart);
            } catch (e) {
                return false;
            }
        },
        studyListfunc() {
            try {
                if (CIQ) {
                    var _that = this;

                    var a = {},
                        b = Object.keys(CIQ.Studies.studyLibrary).sort();
                    b.forEach((key) => {
                        if (_that.searchStudy != "") {
                            if (
                                CIQ.Studies.studyLibrary[key].name
                                    .toLowerCase()
                                    .includes(_that.searchStudy.toLowerCase())
                            ) {
                                a[key] = CIQ.Studies.studyLibrary[key].name;
                            }
                        } else {
                            a[key] = CIQ.Studies.studyLibrary[key].name;
                        }
                    });
                    return a;
                } else {
                    return {};
                }
            } catch (e) {
                console.log("EEE", e);
                return {};
            }
        },
        candleChangeByWS() {
            try {
                this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].High =
                    this.instruments[this.loadChart].B;
                let open =
                    this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Close;
                let high =
                    this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].High;
                let low =
                    this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Low;
                let Volume =
                    this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Volume;
                let close = this.instruments[this.loadChart].B;
                this.setChartValues(open, high, low, close);
                document.title =
                    this.loadChart +
                    " " +
                    LTP +
                    " " +
                    this.formatfloat(
                        parseFloat(
                            (
                                ((this.instruments[this.loadChart].B -
                                    this.instruments[this.loadChart].other.closepx) /
                                    this.instruments[this.loadChart].other.closepx) *
                                100
                            ).toFixed(3)
                        )
                    ) +
                    "%";
            } catch (e) { }
        },
        lastData() {
            try {
                if (
                    this.stxx.chart &&
                    this.stxx.chart.dataSet &&
                    Array.isArray(this.stxx.chart.dataSet) &&
                    this.stxx.chart.dataSet.length > 0
                ) {
                    return new Date(
                        this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].DT
                    );
                } else {
                    return new Date();
                }
            } catch (e) {
                return new Date();
            }
        },
        create_liveCandle() {
            try {
                if (this.loadChart != "") {
                    let newQuotes = {};
                    var d = new Date(); // DT is a string in ISO format, make it a Date instance

                    var getYear = d.getFullYear();
                    var getDate = d.getDate();
                    var getMonth = d.getMonth() + 1;
                    var getHours = d.getHours();
                    var getMins = d.getMinutes();

                    if (getMonth <= 9) {
                        getMonth = "0" + getMonth;
                    }
                    if (getDate <= 9) {
                        getDate = "0" + getDate;
                    }
                    if (getHours.toString().length <= 1) {
                        getHours = "0" + getHours;
                    }
                    if (getMins.toString().length <= 1) {
                        getMins = "0" + getMins;
                    }

                    let CurrentCandleDT = "";
                    if (
                        this.defaultChartInterval == "1D" ||
                        this.defaultChartInterval == "1W" ||
                        this.defaultChartInterval == "1MN"
                    ) {
                        CurrentCandleDT =
                            getYear + "-" + getMonth + "-" + getDate + " " + "00:00:00";
                    } else {
                        CurrentCandleDT =
                            getYear +
                            "-" +
                            getMonth +
                            "-" +
                            getDate +
                            " " +
                            getHours +
                            ":" +
                            getMins +
                            ":00";
                    }
                    // console.log('---------------------------------------------------------------------------')
                    // console.log( "newQuotes ==>",newQuotes)
                    //newQuotes.DT = d.getFullYear()+"-0"+(parseInt(d.getMonth())+1)+"-0"+d.getDate()+" 00:00:00";
                    //price feed microservice

                    //console.log(this.loadChart,"pricefeed",this.instruments[this.loadChart])

                    let LTP = this.instruments[this.loadChart].B;

                    document.title =
                        this.loadChart +
                        " " +
                        LTP +
                        " " +
                        this.formatfloat(
                            parseFloat(
                                (
                                    ((this.instruments[this.loadChart].B -
                                        this.instruments[this.loadChart].other.closepx) /
                                        this.instruments[this.loadChart].other.closepx) *
                                    100
                                ).toFixed(3)
                            )
                        ) +
                        "%";

                    // Previous 1 min

                    // console.log(LTP, "LTP =>", this.stxx.chart.dataSet)

                    const da = new Date();
                    const seconds = da.getSeconds();

                    let open =
                        this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Close;
                    let high =
                        this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].High;
                    let low =
                        this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Low;
                    let Volume =
                        this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Volume;
                    let close = this.instruments[this.loadChart].B;
                    newQuotes.DT =
                        this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].DT;
                    newQuotes.Date =
                        this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Date;
                    // console.log('----->',this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1])
                    this.setChartValues(
                        this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Open,
                        high,
                        low,
                        this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Close
                    );

                    // console.log("low =>",low)
                    // console.log(LTP, "LTP =>", close)

                    if (low != 0 && low != NaN) {
                        // console.log( "low =>",low,'close', close,'high',high,'data===========>',this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1].DT,new Date(this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1].DT))
                        // if(LTP > high){
                        //   high= LTP
                        // }
                        // if(LTP < low){
                        //   low= LTP
                        // }

                        if (
                            ['1M','5M','15M','1H','4H'].includes(this.defaultChartInterval) &&
                            new Date(CurrentCandleDT).getTime() !==
                            new Date(
                                this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].DT
                            ).getTime()
                        ) {
                            open = this.instruments[this.loadChart].B;
                            high = this.instruments[this.loadChart].B;
                            low = this.instruments[this.loadChart].B;
                            close = this.instruments[this.loadChart].B;

                            newQuotes.Open = parseFloat(open);
                            newQuotes.High = parseFloat(high);
                            newQuotes.Low = parseFloat(low);
                            newQuotes.Close = parseFloat(close);
                            newQuotes.Volume = parseFloat(0);
                            newQuotes.Bid = parseFloat(this.instruments[this.loadChart].B);
                            newQuotes.DT = new Date(CurrentCandleDT);
                            newQuotes.Date = new Date(CurrentCandleDT);

                            this.stxx.appendMasterData(newQuotes, null, { fillGaps: true });
                        } else if (
                            this.defaultChartInterval == "1D" &&
                            new Date(CurrentCandleDT).getTime() !==
                            new Date(
                                this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].DT
                            ).getTime()
                        ) {
                            open =
                                this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1]
                                    .Close;
                            high = this.instruments[this.loadChart].HIGH;
                            low = this.instruments[this.loadChart].LOW;
                            close =
                                this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1]
                                    .Close;
                            newQuotes.DT = new Date(CurrentCandleDT);
                            newQuotes.Date = new Date(CurrentCandleDT);
                            this.stxx.appendMasterData(newQuotes, null, { fillGaps: true });
                        } else {
                            newQuotes.Open = parseFloat(open);
                            newQuotes.High = parseFloat(high);
                            newQuotes.Low = parseFloat(low);
                            newQuotes.Close = parseFloat(close);
                            newQuotes.Volume = parseFloat(0);
                            newQuotes.Bid = parseFloat(this.instruments[this.loadChart].B);

                            const d = new Date();
                            let minutes = d.getMinutes();

                            newQuotes.DT = new Date(CurrentCandleDT);
                            newQuotes.Date = new Date(CurrentCandleDT);
                            this.stxx.appendMasterData(newQuotes, null, { fillGaps: true });
                        }

                        //this.stxx.updateChartData(newQuotes, null, {fillGaps: true});
                        // console.log( "newQuotes =>",newQuotes,this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1])
                        return this.instruments[this.loadChart].B;
                    }
                }
            } catch (ex) {
                // console.log("ex in charting app", ex)
            }
        },
        instruments() {
            try {
                this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                return this.$store.getters[Const.GET_PROVIDERS][
                    "priceFeed"
                ].iCache.asKeysValueArrayAll();
            } catch (ex) {
                return [];
            }
        },
        isins() {
            try {
                if(Object.keys(this.instruments).length){
                    let data = Object.values(this.instruments).filter(i => i.other.type != 'stocks');
                    if (data.length) {
                        if (this.search != "") {
                            return Object.values(this.isins).filter((val) =>
                                val.other.isinid.toLowerCase().includes(this.search.toLowerCase())
                            );
                        } else {
                            return Object.values(data);
                        }
                    } else {
                        return [];
                    }
                }
            } catch (ex) {
                return [];
            }
        },
    },
    created() {
        const _that = this;
        if (localStorage.getItem("watchId")) {
            let watchId = localStorage.getItem("watchId");
            this.watchId = watchId == "null" ? null : watchId;
        } else {
            this.watchId = null;
        }
        setTimeout(function () {
            _that.initalizeChart();
        }, 1000);
        // setTimeout(function(){ _that.drawLine() }, 5000);
    },
    mounted() {
        // addEvent(window, "resize", function(event) {
        //   console.log('resized');
        // });
        const _that = this;
        // window.$$(window).resize(function () {
        //   var elem = $("#studytools");
        //   if (elem[0].scrollHeight - 2 <= elem.outerHeight()) {
        //     _that.upButton = false;
        //     _that.downButton = false;
        //   }
        //   if (elem.scrollTop() == 0) {
        //     _that.upButton = false;
        //   } else {
        //     _that.upButton = true;
        //   }
        //   if (elem.scrollTop() + elem.outerHeight() >= elem[0].scrollHeight - 1) {
        //     _that.downButton = false;
        //   } else {
        //     _that.downButton = true;
        //   }
        // });
        // if (
        //   window.$$("#studytools")[0].scrollHeight - 2 <=
        //   $("#studytools").outerHeight()
        // ) {
        //   _that.upButton = false;
        //   _that.downButton = false;
        // }
        // window.$$("#studytools").on("scroll", (e) => {
        //   var elem = $(e.currentTarget);
        //   if (elem[0].scrollHeight - 2 <= elem.outerHeight()) {
        //     _that.upButton = false;
        //     _that.downButton = false;
        //   }
        //   if (elem.scrollTop() == 0) {
        //     _that.upButton = false;
        //   } else {
        //     _that.upButton = true;
        //   }
        //   if (elem.scrollTop() + elem.outerHeight() >= elem[0].scrollHeight - 1) {
        //     _that.downButton = false;
        //   } else {
        //     _that.downButton = true;
        //   }
        // });

        /*if(this.stxx && this.stxx.callbacks){
                this.stxx.callbacks.studyPanelEdit=function(params){
                    var stx=params.stx;
                    var sd=params.sd;
                    if(CIQ.Studies.studyLibrary[sd.type] && CIQ.Studies.studyLibrary[sd.type].name) $$("studyDialog").querySelectorAll(".title")[0].innerHTML=_that.stxx.translateIf(CIQ.Studies.studyLibrary[sd.type].name);
                    else $$("studyDialog").querySelectorAll(".title")[0].innerHTML=sd.type.capitalize();
                    CIQ.Studies.studyDialog(stx, sd.type, $$("studyDialog"), params);
                    CIQ.DialogManager.displayDialog("studyDialog");
                };
            }*/

        // window.$$(document).on("click", function (event) {
        //   if (!$(event.target).closest(".searchPopUp").length) {
        //     _that.searchPopUp = false;
        //   }
        //   if (!$(event.target).closest(".ShowSearchPopup").length) {
        //     _that.ShowSearchPopup = false;
        //   }
        //   if (
        //     !$(event.target).closest("#studyDialog").length &&
        //     !$(event.target).closest("#contextMenu").length &&
        //     !$(event.target).closest(".ciqColorPicker").length &&
        //     _that.studyDialogOpened
        //   ) {
        //     _that.dismissDialog();
        //     _that.studyDialogOpened = false;
        //   }
        // });
        document
            .getElementById("charScreeen")
            .addEventListener("fullscreenchange", (event) => {
                if (document.fullscreenElement) {
                    event.target.classList.add("fullScreen-map");
                    // console.log(`Element: ${document.fullscreenElement.id} entered fullscreen mode.`);
                } else {
                    event.target.classList.remove("fullScreen-map");
                    // console.log('Leaving full-screen mode.');
                }
            });
    },
};
</script>
<style scoped>
@import "/assets/css/chartiq.css";
@import "/assets/css/stx-chart.css";
@import "/assets/css/chart.css";
@import "/assets/css/stx-standard.css";
@import "/assets/css/font-awesome.min.css";
</style>
<style scoped>
.chartContainer {
    width: 100%;
    height: calc(100vh - 120px);
}
.modal.fade.show {
    opacity: 1;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    overflow: auto;
}
</style>
